import React, { Component } from "react";
import "./../css/component/use-case-section.css";
import { Trans, withTranslation } from "react-i18next";
import ContactForm from "./contact-form";

class UseCaseDetailsSection extends Component {
  render() {
    const { title, benefits, cohort, t } = this.props;

    let isOriginLeft = false;

    return (
      <>
        <div className="container">
          <div className="row justify-content-center">
            <div className="text-center">
              <p className="common-header">{t("useCaseDetails.common")}</p>
              <h5>{t(title)}</h5>
            </div>
          </div>

          <div className="container">
            {benefits.map((benefit, i) => {
              isOriginLeft = !isOriginLeft;
              return (
                <div key={i}>
                  <div className="row justify-content-center pt--60">
                    <div className="row justify-content-left justify-content-lg-start pt--50">
                      {isOriginLeft ? (
                        <>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="use-case-benefit">
                              <p className="title">{t(benefit.title)}</p>
                              {benefit.body.map((argument, j) => (
                                <p className="body" key={j}>
                                  <Trans
                                    i18nKey={argument}
                                    components={[<span />]}
                                  />
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="col-12 col-sm-11 col-md-11 col-lg-6 use-case-details-benefit-img">
                            <img src={t(benefit.img)} alt="Benefit"></img>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-12 col-md-9 col-lg-6 use-case-details-benefit-img img-right">
                            <img src={t(benefit.img)} alt="Benefit"></img>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="use-case-benefit">
                              <p className="title">{t(benefit.title)}</p>
                              {benefit.body.map((argument, j) => (
                                <p className="body" key={j}>
                                  <Trans
                                    i18nKey={argument}
                                    components={[<span />]}
                                  />
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="col-12 col-sm-11 col-md-11 col-lg-6 use-case-details-benefit-img img-right-mob">
                            <img src={t(benefit.img)} alt="Benefit"></img>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-12 mt--160 contact-use-case">
          <div className="row justify-content-center pb--30 pt--60">
            <div className="text-center join-text">
              <span className="contact-description">
                <Trans i18nKey="contact.description" />
              </span>
            </div>
          </div>
          <div className="row justify-content-center pb--60">
            <div className="text-center">
              <ContactForm cohort={cohort} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(UseCaseDetailsSection);
