import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { FiPlus, FiMinus } from "react-icons/fi";
import "./../css/component/accordion.css";
import { withTranslation } from "react-i18next";

const FaqList = [
  {
    question: "faqs.faq1.question",
    answer: "faqs.faq1.answer",
  },
  {
    question: "faqs.faq2.question",
    answer: "faqs.faq2.answer",
  },
  {
    question: "faqs.faq3.question",
    answer: "faqs.faq3.answer",
  },
  {
    question: "faqs.faq4.question",
    answer: "faqs.faq4.answer",
  },
];

class Accordion1 extends Component {
  render() {
    const { t } = this.props;

    return (
      <Accordion preExpanded={"0"}>
        {FaqList.map((val, i) => (
          <AccordionItem className="faq" key={i}>
            <AccordionItemHeading className="faq-header">
              <AccordionItemButton className="btn btn-link">
                <span className="icon">
                  <FiMinus className="fa-plus" />
                  <FiPlus className="fa-minus" />
                </span>
                <span className="text">{t(val.question)}</span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="faq-body">
              <p>{t(val.answer)}</p>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    );
  }
}

export default withTranslation()(Accordion1);
