import React, { Component, Fragment, Suspense } from "react";
import "./../css/app.css";
import Header from "./../sections/header";
import Banner from "./../sections/banner";
import AboutUs from "../sections/about-us";
import UseCaseSection from "../sections/use-case-section";
import HowItWorks from "../sections/how-it-works-section";
import AnswerQuestionsSection from "../sections/answer-questions-section";
import Footer from "../sections/footer-section";
import { initAnalytics } from "../GAUtils";

class App extends Component {
  async componentDidMount() {
    initAnalytics("/");
  }

  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document
          .querySelector(".header--fixed")
          .classList.add(["sticky"], ["animated"], ["slideInDown"]);
      } else {
        document
          .querySelector(".header--fixed")
          .classList.remove(["sticky"], ["animated"], ["slideInDown"]);
      }
    });
    return (
      <Fragment>
        <Suspense fallback="loading">
          <div className="App">
            <Header headerStyle={"header header-style-1"}></Header>
            <Banner bannerStyle={"banner-section"}></Banner>
            <AboutUs
              featureStyle={
                "about-us-section pt--60 pb--60 pt_lg--65 pb_lg--80"
              }
            ></AboutUs>
            <UseCaseSection
              useCasesStyle={
                "use-case-section pt--60 pb--60 pt_lg--65 pb_lg--80"
              }
            ></UseCaseSection>
            <HowItWorks
              howItWorksStyle={
                "how-it-works-section pt--60 pb--60 pt_lg--65 pb_lg--80"
              }
            ></HowItWorks>
            <AnswerQuestionsSection
              testimonialStyle={
                "answer-questions-section overflow-hidden pb--50 pt--50"
              }
            ></AnswerQuestionsSection>
            <Footer
              footerStyle={"footer"}
              marginStyle={"pt--100 pt_sm--120 pb--20 pb_lg--20"}
            ></Footer>
          </div>
        </Suspense>
      </Fragment>
    );
  }
}

export default App;
