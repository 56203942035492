import React, { Component } from "react";
import "./../css/footer.css";
import Select from "react-select";
import Flag from "react-flagkit";
import i18n from "../i18n";

class Footer extends Component {
  constructor() {
    super();
    const currentLng = i18n.language.includes("es") ? "ES" : "US";

    this.state = {
      lang: {
        value: i18n.language,
        label: <Flag country={currentLng} size={20} />,
      },
    };

    this.updateLanguage = this.updateLanguage.bind(this);
  }

  updateLanguage(event) {
    const flag = event.value === "es" ? "ES" : "US";
    this.setState({
      lang: { value: event.value, label: <Flag country={flag} size={20} /> },
    });
    i18n.changeLanguage(event.value);
  }

  render() {
    const languageOptions = [
      { value: "es", label: <Flag country="ES" size={20} /> },
      { value: "en", label: <Flag country="US" size={20} /> },
    ];

    return (
      <footer
        className={`${this.props.footerStyle ? this.props.footerStyle : ""}`}
      >
        <div className={"footer-top " + this.props.marginStyle}>
          <div className="container">
            <div className="row pre-footer">
              <div className="col-4 col-sm-2 col-md-2 col-lg-2 footer-logo">
                <div>
                  <Select
                    menuPlacement="top"
                    className="language-picker"
                    options={languageOptions}
                    isSearchable={false}
                    value={this.state.lang}
                    onChange={this.updateLanguage}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6 col-md-4 col-lg-3 footer-logo">
                <img src="assets/images/logo.png" alt="logo" />
              </div>

              <div className="col-md-4 col-lg-6 copyright-footer">
                <p>&copy; Copyrights {new Date().getFullYear()} Bloomide</p>
              </div>

              <div className="col-6 col-sm-6 col-md-4 col-lg-3 pl-lg-3 contact-cta">
                <div>
                  <a
                    href="mailto:info@bloomide.com"
                    target="_top"
                    className="mail-cta"
                  >
                    info@bloomide.com
                  </a>

                  <a
                    href="https://www.linkedin.com/company/bloomide"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="linkdn-ref"
                  >
                    <img
                      src="assets/images/LI.png"
                      style={{ width: "6%" }}
                      alt="LinkedIn"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
