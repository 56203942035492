import React, { Component, Fragment, Suspense } from "react";
import Header from "../sections/header";
import Footer from "../sections/footer-section";

class UseCases extends Component {
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document
          .querySelector(".header--fixed")
          .classList.add(["sticky"], ["animated"], ["slideInDown"]);
      } else {
        document
          .querySelector(".header--fixed")
          .classList.remove(["sticky"], ["animated"], ["slideInDown"]);
      }
    });

    const { Case } = this.props;

    return (
      <Fragment>
        <Suspense fallback="loading">
          <div className="App">
            <Header headerStyle={"header header-style-1"}></Header>
            <Case
              caseStyle={"specific-use-case pt--135 pt_lg--170"}
            />
            <Footer footerStyle={"footer"} marginStyle={"pt--25 pt_sm--25 pt_md--50 pt_lg--50 pb--20 pb_lg--20"}></Footer>
          </div>
        </Suspense>
      </Fragment>
    );
  }
}

export default UseCases;
