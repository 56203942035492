import React, { Component } from "react";
import SectionHeader from "../component/section-header";
import { Trans, withTranslation } from "react-i18next";
import "./../css/about-us.css";

const alignment = "section-header text-center";

class AboutUs extends Component {
  render() {
    const { t } = this.props;

    const title = <h5 className="section-title-white">{t("aboutUs.title")}</h5>;
    const subtitle = (
      <h6 className="section-description">
        <Trans i18nKey="aboutUs.description" />
      </h6>
    );
    return (
      <section
        id="about-us"
        className={`${this.props.featureStyle ? this.props.featureStyle : ""}`}
      >
        <div className="container">
          <SectionHeader
            title={title}
            subtitle={subtitle}
            alignment={alignment}
          />
        </div>
      </section>
    );
  }
}

export default withTranslation()(AboutUs);
