import React, { Component } from "react";
import SectionHeader from "../component/section-header";
import "./../css/component/how-it-works.css";
import Steps from "../component/steps";
import { withTranslation } from "react-i18next";

class HowItWorks extends Component {
  render() {
    const { t } = this.props;
    const title = <h5 className="section-title">{t("howItWorks.title")}</h5>;
    const alignment = "section-header text-center";

    return (
      <section
        id="how-it-works"
        className={`${
          this.props.howItWorksStyle ? this.props.howItWorksStyle : ""
        }`}
      >
        <div className="row justify-content-center">
          <div className="text-center">
            <SectionHeader title={title} alignment={alignment} />
          </div>
        </div>

        <div className="banner-content d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 pt--50">
                <div className="banner-text">
                  <h4 className="how-it-works-title">
                    {t("howItWorks.subtitle")}
                  </h4>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="pricing-tab-content-body">
                <Steps />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(HowItWorks);
