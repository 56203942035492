import React, { Component } from "react";
import "./../css/component/contact.css";
import jsonp from "jsonp";
import { withTranslation } from "react-i18next";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      axEmail: "",
      loading: false,
      error: false,
      success: false,
    };
  }

  onSubmit = (e) => {
    this.setState({
      loading: true,
      error: false,
      success: false,
    });

    e.preventDefault();
    const url =
      "https://gmail.us11.list-manage.com/subscribe/post?u=c2288d4bacc129c1c97717956&amp;id=0349b8a037&amp;f_id=00c8d7e3f0";
    jsonp(
      `${url}&EMAIL=${this.state.axEmail}&COHORT=${this.props.cohort}`,
      { param: "c" },
      (_, data) => {
        const { result } = data;

        if (result === "error") {
          this.setState({
            loading: false,
            error: true,
          });
        } else {
          this.setState({
            loading: false,
            success: true,
          });
        }
      }
    );
  };

  render() {
    const { t } = this.props;

    return (
      <form id="contact" className="contact-form" onSubmit={this.onSubmit}>
        <div
          className={
            this.state.error
              ? "row mailing-list mailing-list-error"
              : "row mailing-list"
          }
        >
          <input
            type="text"
            name="email"
            id="item02"
            value={this.state.axEmail}
            required
            pattern=".*\S+.*"
            onChange={(e) => {
              this.setState({ axEmail: e.target.value });
            }}
            placeholder={t("contact.form.emailPlaceholder")}
          />
          <button
            className="submit-btn"
            type="submit"
            value="submit"
            name="submit"
            id="mc-embedded-subscribe"
            disabled={this.state.loading}
            style={
              this.state.loading
                ? { cursor: "not-allowed", opacity: "0.6" }
                : {}
            }
          >
            {t("contact.form.btn")}
          </button>
        </div>
        {this.state.error && (
          <div className="subscribe-error">{t("contact.form.error")}</div>
        )}
        {this.state.success && (
          <div class="alert alert-success subscribe-success" role="alert">
            {t("contact.form.success")}
          </div>
        )}
      </form>
    );
  }
}

export default withTranslation()(ContactForm);
