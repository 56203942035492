import React, { Component } from "react";
import SectionHeader from "../component/section-header";
import "./../css/use-case.css";
import { withTranslation } from "react-i18next";
import UseCases from "../component/use-cases";

class UseCaseSection extends Component {
  render() {
    const { t } = this.props;

    const title = <h5 className="section-title">{t("useCases.title")}</h5>;
    const alignment = "section-header text-center";

    return (
      <section
        id="use-cases"
        className={`${
          this.props.useCasesStyle ? this.props.useCasesStyle : ""
        }`}
      >
        <div className="row justify-content-center">
          <div className="text-center">
            <SectionHeader title={title} alignment={alignment} />
          </div>
        </div>

        <div className="banner-content d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 pt--50">
                <div className="banner-text">
                  <h4 className="use-cases-title">{t("useCases.subtitle")}</h4>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="pricing-tab-content-body">
                <UseCases></UseCases>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(UseCaseSection);
