import React, { Component } from "react";
import UseCaseDetailsSection from "../component/use-case-details-section";
import { initAnalytics } from "../GAUtils";

class ManagementUseCaseSection extends Component {
  async componentDidMount() {
    initAnalytics("/data-driven-strategies");
  }

  render() {
    const title = "useCaseDetails.management.title";
    const benefits = [
      {
        title: "useCaseDetails.management.useCases.useCase1.title",
        body: [
          "useCaseDetails.management.useCases.useCase1.benefits.benefit1",
          "useCaseDetails.management.useCases.useCase1.benefits.benefit2",
          "useCaseDetails.management.useCases.useCase1.benefits.benefit3",
          "useCaseDetails.management.useCases.useCase1.benefits.benefit4",
        ],
        img: "useCaseDetails.management.useCases.useCase1.img",
      },
      {
        title: "useCaseDetails.management.useCases.useCase2.title",
        body: [
          "useCaseDetails.management.useCases.useCase2.benefits.benefit1",
          "useCaseDetails.management.useCases.useCase2.benefits.benefit2",
          "useCaseDetails.management.useCases.useCase2.benefits.benefit3",
        ],
        img: "useCaseDetails.management.useCases.useCase2.img",
      },
      {
        title: "useCaseDetails.management.useCases.useCase3.title",
        body: [
          "useCaseDetails.management.useCases.useCase3.benefits.benefit1",
          "useCaseDetails.management.useCases.useCase3.benefits.benefit2",
          "useCaseDetails.management.useCases.useCase3.benefits.benefit3",
          "useCaseDetails.management.useCases.useCase3.benefits.benefit4",
        ],
        img: "useCaseDetails.management.useCases.useCase3.img",
      },
    ];

    return (
      <section
        id="management-use-cases"
        className={`${this.props.caseStyle ? this.props.caseStyle : ""}`}
      >
        <UseCaseDetailsSection
          title={title}
          benefits={benefits}
          cohort={"Management Teams"}
        />
      </section>
    );
  }
}

export default ManagementUseCaseSection;
