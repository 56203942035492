import React, { Component } from "react";
import "./../css/component/use-cases.css";
import UseCaseItem from "./use-case-item";

class UseCase extends Component {
  render() {
    return (
      <>
        <div className="row justify-content-center justify-content-lg-start pt--50">
          <UseCaseItem
            id={"investors"}
            cardSizeClass={"col-11 col-md-9 col-lg-7"}
            linkTypeClass={"use-case-link"}
            linkPath={"/investment"}
            additionalFlex={false}
          />

          <UseCaseItem
            id={"hr"}
            cardSizeClass={"col-11 col-md-9 col-lg-5"}
            linkTypeClass={"use-case-hr-link"}
            linkPath={"/hr-consulting-recruiting"}
            additionalFlex={true}
          />
        </div>

        <div className="row justify-content-center justify-content-lg-start pt_lg--30">
          <UseCaseItem
            id={"strategies"}
            cardSizeClass={"col-11 col-md-9 col-lg-5"}
            linkTypeClass={"use-case-link"}
            linkPath={"/data-driven-strategies"}
            additionalFlex={false}
          />

          <UseCaseItem
            id={"business"}
            cardSizeClass={"col-11 col-md-9 col-lg-7"}
            linkTypeClass={"use-case-link"}
            linkPath={"/business-consulting"}
            additionalFlex={false}
          />
        </div>
      </>
    );
  }
}

export default UseCase;
