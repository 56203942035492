import React, { Component } from "react";
import "./../css/banner.css";
import { Trans, withTranslation } from "react-i18next";
import i18n from "../i18n";

class Banner extends Component {
  render() {
    const currentLng = i18n.language.includes("es") ? "ES" : "US";

    return (
      <section
        id="banner"
        className={`${this.props.bannerStyle ? this.props.bannerStyle : ""}`}
      >
        <div className="banner-content d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className={currentLng === "ES" ? "col-lg-8" : "col-lg-6"}>
                <div className="banner-text">
                  <h1 className={"title-" + currentLng}>
                    <Trans i18nKey="banner.title" />
                  </h1>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <img src="assets/images/banner/diagram.png" alt="diagram" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(Banner);
