import React, { Component } from "react";
import SectionHeader from "../component/section-header";
import { Trans, withTranslation } from "react-i18next";
import ContactForm from "../component/contact-form";
import { ImQuotesLeft } from "react-icons/im";
import "./../css/answer-questions-section.css";

class AnswerQuestionsSection extends Component {
  render() {
    const { t } = this.props;
    const title = <h5 className="section-title">{t("contact.title")}</h5>;
    const alignment = "section-header text-center pb--60";
    return (
      <section
        id="answer-questions"
        className={`${
          this.props.testimonialStyle ? this.props.testimonialStyle : ""
        }`}
      >
        <div className="row justify-content-center">
          <div className="text-center">
            <SectionHeader title={title} alignment={alignment} />
          </div>
        </div>

        <div className="row ">
          <div className="container">
            <div className="col-12 d-flex justify-content-start">
              <div className="question text-left">
                <ImQuotesLeft />
                <span>
                  <Trans i18nKey="contact.question1" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt--30">
          <div className="container">
            <div className="col-12 d-flex justify-content-end">
              <div className="question text-left">
                <ImQuotesLeft />
                <span>
                  <Trans i18nKey="contact.question2" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 pt--60">
          <div className="row justify-content-center pb--30">
            <div className="text-center join-text">
              <span>
                <Trans i18nKey="contact.description" />
              </span>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="text-center">
              <ContactForm cohort="General" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(AnswerQuestionsSection);
