import React, { Component } from "react";
import Scrollspy from "react-scrollspy";
import { Link, NavLink } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { withTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import "./../css/header.css";

class Header extends Component {
  menuTrigger() {
    document.querySelector("body").classList.add("mobile-menu-open");
  }
  closeMenuTrigger() {
    document.querySelector("body").classList.remove("mobile-menu-open");
  }
  render() {
    var elements = document.querySelectorAll(".has-childmenu > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { headerStyle } = this.props;

    const { t } = this.props;

    return (
      <header className={`${headerStyle}`}>
        <div id="mainNav" className="main-menu-area header--fixed">
          <div className="container">
            <div className="row m-0 align-items-center">
              <div className="col-lg-2 p-0 d-flex justify-content-between align-items-center">
                <div className="logo">
                  <Link className="navbar-brand navbar-brand1" to="/#">
                    <img src="assets/images/logo.png" alt="logo" />
                  </Link>
                  <Link className="navbar-brand navbar-brand2" to="/#">
                    <img src="assets/images/logo.png" alt="logo" />
                  </Link>
                </div>
                <div className="menu-bar d-lg-none" onClick={this.menuTrigger}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="op-mobile-menu col-lg-10 p-0 d-lg-flex align-items-center justify-content-end">
                <div className="m-menu-header d-flex justify-content-end d-lg-none">
                  <span
                    className="close-button"
                    onClick={this.closeMenuTrigger}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </div>
                <Scrollspy
                  className="custom-border-top nav-menu d-lg-flex flex-wrap list-unstyled  mb-0 justify-content-center"
                  offset={-200}
                >
                  <li className="has-childmenu">
                    <NavLink to="#">
                      {t("header.useCases.title")}
                      <IoIosArrowDown style={{ marginLeft: "4px" }} />
                    </NavLink>
                    <ul className="submenu">
                      <li>
                        <NavLink
                          onClick={this.closeMenuTrigger}
                          to={t("header.useCases.invest.link")}
                        >
                          <span>{t("header.useCases.invest.title")}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={this.closeMenuTrigger}
                          to={t("header.useCases.hr.link")}
                        >
                          <span>{t("header.useCases.hr.title")}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={this.closeMenuTrigger}
                          to={t("header.useCases.business.link")}
                        >
                          <span>{t("header.useCases.business.title")}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={this.closeMenuTrigger}
                          to={t("header.useCases.strategies.link")}
                        >
                          <span>{t("header.useCases.strategies.title")}</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="faq-head">
                    <NavLink onClick={this.closeMenuTrigger} to="/faq">
                      <span>{t("header.faq")}</span>
                    </NavLink>
                  </li>
                  <li>
                    <HashLink to="#contact">
                      <button>
                        {" "}
                        <span>{t("header.join")}</span>
                      </button>
                    </HashLink>
                  </li>
                </Scrollspy>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default withTranslation()(Header);
