import React, { Component } from "react";
import SectionHeader from "./../component/section-header";
import Accordion1 from "./../component/accordion";
import { Trans, withTranslation } from "react-i18next";
import "./../css/faq.css";
import { initAnalytics } from "../GAUtils";

class FaqSection extends Component {
  async componentDidMount() {
    initAnalytics("/faq");
  }

  render() {
    const { t } = this.props;

    const title = <h3 className="mb--20">{t("faqs.title")}</h3>;
    const subtitle = (
      <h6>
        <Trans
          i18nKey={"faqs.description"}
          components={[
            <a
              href="mailto:info@bloomide.com"
              target="_top"
              className="mail-cta"
            >""</a>,
            <a
              href="https://www.linkedin.com/company/bloomide"
              target="_blank"
              rel="noopener noreferrer"
              className="mail-cta"
            >""</a>,
          ]}
        />
      </h6>
    );
    const alignment = "section-header text-center pb--60";

    return (
      <section
        id="faq"
        className={`${this.props.faqStyle ? this.props.faqStyle : ""}`}
      >
        <div className="container p-0">
          <div className="container-sm">
            <SectionHeader
              title={title}
              subtitle={subtitle}
              alignment={alignment}
            />

            <div className="faq-container">
              <Accordion1 />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(FaqSection);
