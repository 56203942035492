import React, { Component } from "react";
import UseCaseDetailsSection from "../component/use-case-details-section";
import { initAnalytics } from "../GAUtils";

class InvestmentUseCaseSection extends Component {
  async componentDidMount() {
    initAnalytics("/investment");
  }

  render() {
    const title = "useCaseDetails.investment.title";
    const benefits = [
      {
        title: "useCaseDetails.investment.useCases.useCase1.title",
        body: [
          "useCaseDetails.investment.useCases.useCase1.benefits.benefit1",
          "useCaseDetails.investment.useCases.useCase1.benefits.benefit2",
          "useCaseDetails.investment.useCases.useCase1.benefits.benefit3",
          "useCaseDetails.investment.useCases.useCase1.benefits.benefit4",
        ],
        img: "useCaseDetails.investment.useCases.useCase1.img",
      },
      {
        title: "useCaseDetails.investment.useCases.useCase2.title",
        body: [
          "useCaseDetails.investment.useCases.useCase2.benefits.benefit1",
          "useCaseDetails.investment.useCases.useCase2.benefits.benefit2",
          "useCaseDetails.investment.useCases.useCase2.benefits.benefit3",
        ],
        img: "useCaseDetails.investment.useCases.useCase2.img",
      },
      {
        title: "useCaseDetails.investment.useCases.useCase3.title",
        body: [
          "useCaseDetails.investment.useCases.useCase3.benefits.benefit1",
          "useCaseDetails.investment.useCases.useCase3.benefits.benefit2",
          "useCaseDetails.investment.useCases.useCase3.benefits.benefit3",
        ],
        img: "useCaseDetails.investment.useCases.useCase3.img",
      },
    ];

    return (
      <section
        id="investment-use-cases"
        className={`${this.props.caseStyle ? this.props.caseStyle : ""}`}
      >
        <UseCaseDetailsSection
          title={title}
          benefits={benefits}
          cohort={"Investors"}
        />
      </section>
    );
  }
}

export default InvestmentUseCaseSection;
