import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { withTranslation } from "react-i18next";

class UseCaseLink extends Component {
  render() {
    const { linkPath, linkText } = this.props;

    return (
      <NavLink to={linkPath}>
        <span>
          {linkText}
          <IoIosArrowForward style={{ marginLeft: "4px" }} />
        </span>
      </NavLink>
    );
  }
}

class UseCaseItem extends Component {
  render() {
    const { id, cardSizeClass, linkTypeClass, linkPath, additionalFlex, t } =
      this.props;

    const transKey = "useCases." + id;

    return (
      <div className={cardSizeClass + " use-case-card"}>
        <div className={"use-case-item use-case-" + id + " mb_lg--0"}>
          <div className="use-case-wrapper">
            <div>
              <p className="use-case-head">{t(transKey + ".title")}</p>
              <p className="use-case-subtitle">{t(transKey + ".subtitle")}</p>
            </div>

            <div className={linkTypeClass}>
              {additionalFlex ? (
                <>
                  <div className="additional-flex">
                    <UseCaseLink
                      linkPath={linkPath}
                      linkText={t("useCases.link")}
                    />
                  </div>
                  <img src={t(transKey + ".imgPath")} alt="Use Case" />
                </>
              ) : (
                <UseCaseLink
                  linkPath={linkPath}
                  linkText={t("useCases.link")}
                />
              )}
            </div>
          </div>

          {!additionalFlex && <img src={t(transKey + ".imgPath")} alt="Use Case" />}
        </div>
      </div>
    );
  }
}

export default withTranslation()(UseCaseItem);
