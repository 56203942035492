import React, { Component } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./home/App";
import Faq from "./home/Faq";
import HRUseCaseSection from "./sections/hr-use-case-section";
import BusinessUseCaseSection from "./sections/business-use-case-section";
import InvestmentUseCaseSection from "./sections/investment-use-case-section";
import ManagementUseCaseSection from "./sections/management-use-case-section";
import UseCases from "./home/Use-Cases";
import ScrollToTop from "./component/scrollTotop";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import "./i18n";

class Root extends Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={App} />
          <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={Faq} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/hr-consulting-recruiting`}
            component={() => <UseCases Case={HRUseCaseSection} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/business-consulting`}
            component={() => <UseCases Case={BusinessUseCaseSection} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/investment`}
            component={() => <UseCases Case={InvestmentUseCaseSection} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/data-driven-strategies`}
            component={() => <UseCases Case={ManagementUseCaseSection} />}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
