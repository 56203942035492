import React, { Component } from "react";
import "./../css/component/steps.css";
import Step from "./step";

class Steps extends Component {
  render() {
    return (
      <>
        <div className="row justify-content-center justify-content-lg-start pt--50">
          <Step cardSizeClass={"col-11 col-md-9 col-lg-5"} id={"step1"} stepNum={"1"}/>
          <Step cardSizeClass={"col-11 col-md-9 col-lg-7"} id={"step2"} stepNum={"2"} />
          
        </div>

        <div className="row justify-content-center justify-content-lg-start pt_lg--30">
            <Step cardSizeClass={"col-11 col-md-9 col-lg-6"} id={"step3"} stepNum={"3"}/>
            <Step cardSizeClass={"col-11 col-md-9 col-lg-6"} id={"step4"} stepNum={"4"}/>
        </div>
      </>
    );
  }
}

export default Steps;
