import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Step extends Component {
  render() {
    const { id, cardSizeClass, stepNum, t } = this.props;

    const transKey = "howItWorks." + id;

    return (
      <div className={cardSizeClass + " step-card"}>
        <div className={"step-item card-" + id + " mb_lg--0"}>
          <div>
            <p className="step-head">
              <span className="step-number">{stepNum}</span>
              {t(transKey + ".title")}
            </p>
          </div>

          <img src={t(transKey + ".imgPath")} alt="Step" />
        </div>
      </div>
    );
  }
}

export default withTranslation()(Step);
