import React, { Component } from "react";
import UseCaseDetailsSection from "../component/use-case-details-section";
import { initAnalytics } from "../GAUtils";

class BusinessUseCaseSection extends Component {
  async componentDidMount() {
    initAnalytics("/business-consulting");
  }

  render() {
    const title = "useCaseDetails.business.title";
    const benefits = [
      {
        title: "useCaseDetails.business.useCases.useCase1.title",
        body: [
          "useCaseDetails.business.useCases.useCase1.benefits.benefit1",
          "useCaseDetails.business.useCases.useCase1.benefits.benefit2",
          "useCaseDetails.business.useCases.useCase1.benefits.benefit3",
          "useCaseDetails.business.useCases.useCase1.benefits.benefit4",
        ],
        img: "useCaseDetails.business.useCases.useCase1.img",
      },
      {
        title: "useCaseDetails.business.useCases.useCase2.title",
        body: [
          "useCaseDetails.business.useCases.useCase2.benefits.benefit1",
          "useCaseDetails.business.useCases.useCase2.benefits.benefit2",
          "useCaseDetails.business.useCases.useCase2.benefits.benefit3",
          "useCaseDetails.business.useCases.useCase2.benefits.benefit4",
          "useCaseDetails.business.useCases.useCase2.benefits.benefit5",
        ],
        img: "useCaseDetails.business.useCases.useCase2.img",
      },
    ];

    return (
      <section
        id="business-use-cases"
        className={`${this.props.caseStyle ? this.props.caseStyle : ""}`}
      >
        <UseCaseDetailsSection
          title={title}
          benefits={benefits}
          cohort={"Business Consultants"}
        />
      </section>
    );
  }
}

export default BusinessUseCaseSection;
